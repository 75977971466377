.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8); /* Transparent background */
    z-index: 9999; /* Place it above all content */
  }
  
  .loader {
    /* Add your loader styling here */
  }
  